import React from 'react';
import styled from 'styled-components';
import typo from '../../styles/typography';
import FooterContent from '../molecules/FooterContent';

const StyledFooter = styled.footer`
  margin-top: 30px;
  background-color: ${({ theme }) => theme.colors.grey};
  font-family: ${typo.lato};
  p {
    color: ${({ theme }) => theme.colors.dark};
  }

  a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.touch};
    text-decoration: none;
  }

  display: felex;
  justify-content: center;
`;

const Footer = () => (
  <StyledFooter>
    <FooterContent />
  </StyledFooter>
);

export default Footer;

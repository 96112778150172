import * as React from "react"
import Layout from '../components/templates/Layout';
import Navbar from '../components/molecules/Navbar';
import Footer from '../components/organisms/Footer';
import Statute from '../components/organisms/Statute';

const StatutePage = () => (
  <>
    <Layout>
      <Navbar />
      <Statute />
      <Footer />

    </Layout>
  </>
);

export default StatutePage;

import React from 'react';
import styled from 'styled-components';
import StatuteDetails from '../molecules/StatuteDeatails';

const StyledStatute = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
`;

const Statute = () => (
  <StyledStatute>
      <StatuteDetails />
  </StyledStatute>
);

export default Statute;

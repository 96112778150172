import React from 'react';
import styled from 'styled-components';

const StyledStatuteDetails = styled.div`
  justify-self: center;
  margin-top: 30px;
  padding-bottom: 120px;
  grid-column: 1/3;

  h1 {
    font-size: 1.75em;
    color: black;
  }
  
  h2 {
    font-size: 1.2em;
    color: black;
  }
  
  a{
    text-align: center;
  }
  
  p {
    color: black;
  }
  
  & > div {
    padding: 30px;
    padding-right: 0;
    margin-top: 30px;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .bold-text {
    margin-left: 20px;
    margin-right: 5px;
    font-weight: bold;
    text-align: center;
  }

  .line-break {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0;
  }

  @media ${({ theme: { media } }) => media.tablet} {
    justify-self: start;
    grid-column: 2/3;
    padding-bottom: 0px;

    .bold-text {
      margin-left: 8px;
    }

    & > div {
      padding-left: 0px;
    }
    
  }

  @media ${({ theme: { media } }) => media.laptop} {
    width: 100%;
    .line-break {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .statuteSection {
    margin: 20px;
    color: black;
  }
  
  .statuteSectionContent ol {
    color:black;
    list-style-type: decimal;
    list-style-position: inside;
    overflow: visible;
  }  
  .statuteSectionContent li {
    color:black;
    display: list-item;
    list-style-position: inside;
    overflow: visible;
  }
  .statute {
    margin: 40px;
  }
`;

const StatuteDetails = () => (
    <StyledStatuteDetails>
      <div className="line-break">
        <h1 className="bold-text">Regulamin</h1>
        <br/>
      </div>
        <div className="statute">
            Regulamin sklepu internetowego
<br/>
            § 1<br/>
            Postanowienia wstępne<br/>
<br/>
<br/>
            1. Sklep internetowy Szum Stepu i Pustyni, dostępny pod adresem internetowym szumstepuipustyni.pl, prowadzony jest przez Tomasza Drzewieckiego prowadzącego działalność gospodarczą pod firmą Pitagoras Tomasz Drzewiecki, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej (CEIDG) prowadzonej przez ministra właściwego ds. gospodarki, NIP 7842352708 , REGON 301960891<br/>
<br/>
            2. Niniejszy regulamin skierowany jest do Konsumentów i określa zasady i tryb zawierania z Konsumentem Umowy Sprzedaży na odległość za pośrednictwem Sklepu.<br/>
<br/>
            § 2<br/>
            Definicje<br/>
<br/>
            1. Konsument - osoba fizyczna zawierająca ze Sprzedawcą umowę w ramach Sklepu, której przedmiot nie jest związany bezpośrednio z jej działalnością gospodarczą lub zawodową.<br/>
            2. Sprzedawca - osoba fizyczna prowadząca działalność gospodarczą pod firmą Pitagoras Tomasz Drzewiecki, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej (CEIDG) prowadzonej przez ministra właściwego ds. gospodarki, NIP 7842352708 , REGON 301960891<br/>
            2. Klient - każdy podmiot dokonujący zakupów za pośrednictwem Sklepu.<br/>
            3. Przedsiębiorca - osoba fizyczna, osoba prawna i jednostka organizacyjna niebędąca osobą prawną, której odrębna ustawa przyznaje zdolność prawną, wykonująca we własnym imieniu działalność gospodarczą, która korzysta ze Sklepu.<br/>
            4. Sklep - sklep internetowy prowadzony przez Sprzedawcę pod adresem internetowym szumstepuipustyni.pl<br/>
            5. Umowa zawarta na odległość - umowa zawarta z Klientem w ramach zorganizowanego systemu zawierania umów na odległość (w ramach Sklepu), bez jednoczesnej fizycznej obecności stron, z wyłącznym wykorzystaniem jednego lub większej liczby środków porozumiewania się na odległość do chwili zawarcia umowy włącznie.<br/>
            6. Regulamin - niniejszy regulamin Sklepu.<br/>
            7. Zamówienie - oświadczenie woli Klienta składane za pomocą Formularza Zamówienia i zmierzające bezpośrednio do zawarcia Umowy Sprzedaży Produktu lub Produktów ze Sprzedawcą.<br/>
            8. Konto - konto klienta w Sklepie, są w nim gromadzone są dane podane przez Klienta oraz informacje o złożonych przez niego Zamówieniach w Sklepie.<br/>
            9. Formularz rejestracji - formularz dostępny w Sklepie, umożliwiający utworzenie Konta.<br/>
            10. Formularz zamówienia - interaktywny formularz dostępny w Sklepie umożliwiający złożenie Zamówienia, w szczególności poprzez dodanie Produktów do Koszyka oraz określenie warunków Umowy Sprzedaży, w tym sposobu dostawy i płatności.<br/>
            11. Koszyk – element oprogramowania Sklepu, w którym widoczne są wybrane przez Klienta Produkty do zakupu, a także istnieje możliwość ustalenia i modyfikacji danych Zamówienia, w szczególności ilości produktów.<br/>
            12. Produkt - dostępna w Sklepie rzecz ruchoma/usługa będąca przedmiotem Umowy Sprzedaży między Klientem a Sprzedawcą.<br/>
            13. Umowa Sprzedaży - umowa sprzedaży Produktu zawierana albo zawarta między Klientem a Sprzedawcą za pośrednictwem Sklepu internetowego. Przez Umowę Sprzedaży rozumie się też - stosowanie do cech Produktu - umowę o świadczenie usług i umowę o dzieło.<br/>
<br/>
            § 3<br/>
            Kontakt ze Sklepem<br/>
<br/>
            1. Adres Sprzedawcy: ul. Kolejowa 4/4, 64-200 Wolsztyn<br/>
            2. Adres e-mail Sprzedawcy:  info@szumstepuipustyni.pl<br/>
            3. Klient może porozumiewać się ze Sprzedawcą za pomocą adresów podanych w niniejszym paragrafie.<br/>
<br/>
            § 4<br/>
            Wymagania techniczne<br/>
<br/>
            Do korzystania ze Sklepu, w tym przeglądania asortymentu Sklepu oraz składania zamówień na Produkty, niezbędne są:<br/>
            a. urządzenie końcowe z dostępem do sieci Internet i przeglądarką internetową,<br/>
            b. aktywne konto poczty elektronicznej (e-mail),<br/>
            c. włączona obsługa plików cookies.<br/>
<br/>
            § 5<br/>
            Informacje ogólne<br/>
<br/>
            1. Sprzedawca w najszerszym dopuszczalnym przez prawo zakresie nie ponosi odpowiedzialności za zakłócenia w tym przerwy w funkcjonowaniu Sklepu spowodowane siłą wyższą, niedozwolonym działaniem osób trzecich lub niekompatybilnością Sklepu internetowego z infrastrukturą techniczną Klienta.<br/>
            2. Przeglądanie asortymentu Sklepu nie wymaga zakładania Konta. Składanie zamówień przez Klienta na Produkty znajdujące się w asortymencie Sklepu możliwe jest albo po założeniu Konta zgodnie z postanowieniami § 6 Regulaminu albo przez podanie niezbędnych danych osobowych i adresowych umożliwiających realizację Zamówienia bez zakładania Konta.<br/>
            3.  Ceny podane w Sklepie są podane w polskich złotych i są cenami brutto (uwzględniają podatek VAT).<br/>
            4. Na końcową (ostateczną) kwotę do zapłaty przez Klienta składa się cena za Produkt oraz koszt dostawy (w tym opłaty za transport, dostarczenie i usługi pocztowe), o której Klient jest informowany na stronach Sklepu w trakcie składania Zamówienia, w tym także w chwili wyrażenia woli związania się Umową Sprzedaży.<br/>
            5. W przypadku Umowy obejmującej prenumeratę lub świadczenie usług na czas nieoznaczony końcową (ostateczną) ceną jest łączna cena obejmująca wszystkie płatności za okres rozliczeniowy.<br/>
            6. Gdy charakter przedmiotu Umowy nie pozwala, rozsądnie oceniając, na wcześniejsze obliczenie wysokości końcowej (ostatecznej) ceny, informacja o sposobie, w jaki cena będzie obliczana, a także o opłatach za transport, dostarczenie, usługi pocztowe oraz o innych kosztach, będzie podana w Sklepie w opisie Produktu.<br/>
<br/>
            § 6<br/>
            Zakładanie Konta w Sklepie<br/>
<br/>
            1. Aby założyć Konto w Sklepie, należy wypełnić Formularz rejestracji. Niezbędne jest podanie następujących danych Imienia, Nazwiska, Adresu dostawy, adresu e-mail.<br/>
            2. Założenie Konta w Sklepie jest darmowe.<br/>
            3. Logowanie się na Konto odbywa się poprzez podanie loginu i hasła ustanowionych w Formularzu rejestracji.<br/>
            4. Klient ma możliwość w każdej chwili, bez podania przyczyny i bez ponoszenia z tego tytułu jakichkolwiek opłat usunąć Konto poprzez wysłanie stosownego żądania do Sprzedawcy, w szczególności za pośrednictwem poczty elektronicznej lub pisemnie na adresy podane w § 3.<br/>
<br/>
            § 7<br/>
            Zasady składania Zamówienia<br/>
<br/>
            W celu złożenia Zamówienia należy:<br/>
            1. wybrać Produkt będący przedmiotem Zamówienia, a następnie kliknąć przycisk „Zamów książkę” (lub równoznaczny);<br/>
            2. skorzystać z możliwości złożenia Zamówienia bez rejestracji;<br/>
            3. jeżeli wybrano możliwość złożenia Zamówienia bez rejestracji - wypełnić Formularz zamówienia poprzez wpisanie danych odbiorcy Zamówienia oraz adresu, na który ma nastąpić dostawa Produktu, wybrać rodzaj przesyłki (sposób dostarczenia Produktu), wpisać dane do faktury, jeśli są inne niż dane odbiorcy Zamówienia,<br/>
            4. kliknąć przycisk “Zamawiam i płacę”,<br/>
            5. wybrać jeden z dostępnych sposobów płatności i w zależności od sposobu płatności, opłacić zamówienie w określonym terminie, z zastrzeżeniem § 8 pkt 3.<br/>
<br/>
            § 8<br/>
            Oferowane metody dostawy oraz płatności<br/>
<br/>
            1. Klient może skorzystać z następujących metod dostawy lub odbioru zamówionego Produktu:<br/>
            a. Przesyłka pocztowa polecona,<br/>
            b. Przesyłka kurierska.<br/>
            2. Klient może skorzystać z następujących metod płatności:<br/>
            a. Płatność przelewem na konto Sprzedawcy<br/>
            b. Płatności elektroniczne<br/>
            c. Płatność kartą płatniczą.<br/>
            3. Szczegółowe informacje na temat metod dostawy oraz akceptowalnych metod płatności znajdują się za stronach Sklepu.<br/>
<br/>
            § 9<br/>
            Wykonanie umowy sprzedaży<br/>
<br/>
            1. Zawarcie Umowy Sprzedaży między Klientem a Sprzedawcą następuje po uprzednim złożeniu przez Klienta Zamówienia za pomocą Formularza zamówienia w Sklepie internetowym zgodnie z § 7 Regulaminu.<br/>
            2. Po złożeniu Zamówienia Sprzedawca niezwłocznie potwierdza jego otrzymanie oraz jednocześnie przyjmuje Zamówienie do realizacji. Potwierdzenie otrzymania Zamówienia i jego przyjęcie do realizacji następuje poprzez przesłanie przez Sprzedawcę Klientowi stosownej wiadomości e-mail na podany w trakcie składania Zamówienia adres poczty elektronicznej Klienta, która zawiera co najmniej oświadczenia Sprzedawcy o otrzymaniu Zamówienia i o jego przyjęciu do realizacji oraz potwierdzenie zawarcia Umowy Sprzedaży. Z chwilą otrzymania przez Klienta powyższej wiadomości e-mail zostaje zawarta Umowa Sprzedaży między Klientem a Sprzedawcą.<br/>
            3. W przypadku wyboru przez Klienta:<br/>
            a. płatności przelewem, płatności elektronicznych albo płatności kartą płatniczą, Klient obowiązany jest do dokonania płatności w terminie 2 dni kalendarzowych od dnia zawarcia Umowy Sprzedaży - w przeciwnym razie zamówienie zostanie anulowane.<br/>
            4. Jeżeli Klient wybrał sposób dostawy inny niż odbiór osobisty, Produkt zostanie wysłany przez Sprzedawcę w terminie wskazanym w jego opisie (z zastrzeżeniem ustępu 5 niniejszego paragrafu), w sposób wybrany przez Klienta podczas składania Zamówienia.<br/>
            5. A W przypadku zamówienia Produktów o różnych terminach dostawy, terminem dostawy jest najdłuższy podany termin.<br/>
            B W przypadku zamówienia Produktów o różnych terminach dostawy, Klient ma możliwość żądania dostarczenia Produktów częściami lub też dostarczenia wszystkich Produktów po skompletowaniu całego zamówienia.<br/>
            6. Początek biegu terminu dostawy Produktu do Klienta liczy się w następujący sposób:<br/>
            a. W przypadku wyboru przez Klienta sposobu płatności przelewem, płatności elektroniczne lub kartą płatniczą - od dnia uznania rachunku bankowego Sprzedawcy.<br/>
<br/>
            7. W przypadku zamówienia Produktów o różnych terminach gotowości do odbioru, terminem gotowości do odbioru jest najdłuższy podany termin.<br/>
            8. Dostawa Produktu odbywa się wyłącznie na terenie Polski.<br/>
<br/>
            § 10<br/>
            Prawo odstąpienia od umowy<br/>
<br/>
            1. Konsument może w terminie 14 dni odstąpić od Umowy Sprzedaży bez podania jakiejkolwiek przyczyny.<br/>
            2. Bieg terminu określonego w ust. 1 rozpoczyna się od dostarczenia Produktu Konsumentowi lub wskazanej przez niego osobie innej niż przewoźnik.<br/>
            3. W przypadku Umowy, która obejmuje wiele Produktów, które są dostarczane osobno, partiami lub w częściach, termin wskazany w ust. 1 biegnie od dostawy ostatniej rzeczy, partii lub części.<br/>
            4. W przypadku Umowy, która polega na regularnym dostarczaniu Produktów przez czas oznaczony (prenumerata), termin wskazany w ust. 1 biegnie od objęcia w posiadanie pierwszej z rzeczy.<br/>
            5. Konsument może odstąpić od Umowy, składając Sprzedawcy oświadczenie o odstąpieniu od Umowy. Do zachowania terminu odstąpienia od Umowy wystarczy wysłanie przez Konsumenta oświadczenia przed upływem tego terminu.<br/>
            6. Oświadczenie może być wysłane za pomocą tradycyjnej poczty, faxem bądź drogą elektroniczną poprzez przesłanie oświadczenia na adres e-mail Sprzedawcy lub przez złożenie oświadczenia na stronie internetowej Sprzedawcy - dane kontaktowe Sprzedawcy zostały określone w § 3. Oświadczenie można złożyć również na formularzu, którego wzór stanowi załącznik nr 1 do niniejszego Regulaminu oraz załącznik do ustawy z dnia 30 maja 2014 roku o prawach konsumenta, jednak nie jest to obowiązkowe.<br/>
            7. W przypadku przesłania oświadczenia przez Konsumenta drogą elektroniczną, Sprzedawca niezwłocznie prześle Konsumentowi na podany przez Konsumenta adres e-mail potwierdzenie otrzymania oświadczenia o odstąpieniu od Umowy.<br/>
            8. Skutki odstąpienia od Umowy:<br/>
            a. W przypadku odstąpienia od Umowy zawartej na odległość Umowę uważa się za niezawartą.<br/>
            b. W przypadku odstąpienia od Umowy Sprzedawca zwraca Konsumentowi niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od Umowy, wszystkie dokonane przez niego płatności, w tym koszty dostarczenia rzeczy, z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Konsumenta sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez Sprzedawcę.<br/>
            c. Zwrotu płatności Sprzedawca dokona przy użyciu takich samych metod płatności, jakie zostały przez Konsumenta użyte w pierwotnej transakcji, chyba że Konsument wyraźnie zgodził się na inne rozwiązanie, które nie będzie się wiązało dla niego z żadnymi kosztami.<br/>
            d. Sprzedawca może wstrzymać się ze zwrotem płatności do czasu otrzymania Produktu z powrotem lub do czasu dostarczenia mu dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.<br/>
            e. Konsument powinien odesłać Produkt na adres Sprzedawcy podany w niniejszym Regulaminie niezwłocznie, nie później niż 14 dni od dnia, w którym poinformował Sprzedawcę o odstąpieniu od Umowy. Termin zostanie zachowany, jeśli Konsument odeśle Produkt przed upływem terminu 14 dni.<br/>
            f. Konsument ponosi bezpośrednie koszty zwrotu Produktu, także koszty zwrotu Produktu, jeśli ze względu na swój charakter Produkt ten nie mógł zostać w zwykłym trybie odesłany pocztą.<br/>
            g. Konsument odpowiada tylko za zmniejszenie wartości Produktu wynikające z korzystania z niego w sposób inny niż było to konieczne do stwierdzenia charakteru, cech i funkcjonowania Produktu.<br/>
            9. W przypadku gdy ze względu na charakter Produktu nie może on zostać odesłany w zwykłym trybie pocztą, informacja o tym, a także o kosztach zwrotu Produktu, będzie się znajdować w opisie Produktu w Sklepie.<br/>
            10. Prawo do odstąpienia od umowy zawartej na odległość nie przysługuje Konsumentowi w odniesieniu do Umowy:<br/>
            a. w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji Konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb,<br/>
            b. w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu,<br/>
            c. w które przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia,<br/>
            d. o świadczenie usług, jeżeli Sprzedawca wykonał w pełni usługę za wyraźną zgodą Konsumenta, który został poinformowany przez rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Sprzedawcę utraci prawo odstąpienia od Umowy,<br/>
            e. w którym cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którym Sprzedawca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od Umowy,<br/>
            f. w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami,<br/>
            g. w której przedmiotem świadczenia są napoje alkoholowe, których cena została uzgodniona przy zawarciu umowy sprzedaży, a których dostarczenie może nastąpić dopiero po upływie 30 dni i których wartość zależy od wahań na rynku, nad którymi Sprzedawca nie ma kontroli,<br/>
            h. w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu,<br/>
            i. o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o prenumeratę,<br/>
            j. o dostarczenie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez Sprzedawcę o utracie prawa odstąpienia od Umowy,<br/>
<br/>
            § 11<br/>
            Reklamacja i gwarancja<br/>
<br/>
            1. Umową Sprzedaży objęte są nowe Produkty.<br/>
            2. Sprzedawca jest obowiązany dostarczyć Klientowi rzecz wolną od wad.<br/>
            3. W przypadku wystąpienia wady zakupionego u Sprzedawcy towaru Klient ma prawo do reklamacji w oparciu o przepisy dotyczące rękojmi w kodeksie cywilnym.<br/>
            4. Reklamację należy zgłosić pisemnie lub drogą elektroniczną na podane w niniejszym Regulaminie adresy Sprzedawcy.<br/>
            5. Zaleca się, aby w reklamacji zawrzeć m.in. zwięzły opis wady, okoliczności (w tym datę) jej wystąpienia, dane Klienta składającego reklamację, oraz żądanie Klienta w związku z wadą towaru.<br/>
            6. Sprzedawca ustosunkuje się do żądania reklamacyjnego niezwłocznie, nie później niż w terminie 14 dni, a jeśli nie zrobi tego w tym terminie, uważa się, że żądanie Klienta uznał za uzasadnione.<br/>
            7. Towary odsyłane w ramach procedury reklamacyjnej należy wysyłać na adres podany w § 3 niniejszego Regulaminu.<br/>
            8. W przypadku, gdy na Produkt została udzielona gwarancja, informacja o niej, a także jej treść, będą zawarte przy opisie Produktu w Sklepie.<br/>
<br/>
<br/>
            § 12<br/>
            Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń<br/>
<br/>
            1. Szczegółowe informacje dotyczące możliwości skorzystania przez Konsumenta z  pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów: http://www.uokik.gov.pl/spory_konsumenckie.php; http://www.uokik.gov.pl/sprawy_indywidualne.php oraz http://www.uokik.gov.pl/wazne_adresy.php.<br/>
            2. Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:<br/>
            a. Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o rozstrzygnięcie sporu wynikłego z Umowy zawartej ze Sprzedawcą.<br/>
            b. Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.), z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu między Konsumentem a Sprzedawcą.<br/>
            c. Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między nim a Sprzedawcą, korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).<br/>
<br/>
            § 13<br/>
            Dane osobowe w Sklepie internetowym<br/>
<br/>
            1. Administratorem danych osobowych Klientów zbieranych za pośrednictwem Sklepu internetowego jest Sprzedawca.<br/>
            2. Dane osobowe Klientów zbierane przez administratora za pośrednictwem Sklepu internetowego zbierane są w celu realizacji Umowy Sprzedaży, a jeżeli Klient wyrazi na to zgodę - także w celu marketingowym.<br/>
            3. Odbiorcami danych osobowych Klientów Sklepu internetowego mogą być:<br/>
            a. W przypadku Klienta, który korzysta w Sklepie internetowym ze sposobu dostawy przesyłką pocztową lub przesyłką kurierską, Administrator udostępnia zebrane dane osobowe Klienta wybranemu przewoźnikowi lub pośrednikowi realizującemu przesyłki na zlecenie Administratora.<br/>
            b. W przypadku Klienta, który korzysta w Sklepie internetowym ze sposobu płatności elektronicznych lub kartą płatniczą Administrator udostępnia zebrane dane osobowe Klienta, wybranemu podmiotowi obsługującemu powyższe płatności w Sklepie internetowym.<br/>
            4. Klient ma prawo dostępu do treści swoich danych oraz ich poprawiania.<br/>
            5. Podanie danych osobowych jest dobrowolne, aczkolwiek niepodanie wskazanych w Regulaminie danych osobowych niezbędnych do zawarcia Umowy Sprzedaży skutkuje brakiem możliwości zawarcia tejże umowy.<br/>
<br/>
            § 14<br/>
            Postanowienia końcowe<br/>
<br/>
            1. Umowy zawierane poprzez Sklep internetowy zawierane są w języku polskim.<br/>
            2. Sprzedawca zastrzega sobie prawo do dokonywania zmian Regulaminu z ważnych przyczyn to jest: zmiany przepisów prawa, zmiany sposobów płatności i dostaw - w zakresie, w jakim te zmiany wpływają na realizację postanowień niniejszego Regulaminu. O każdej zmianie Sprzedawca poinformuje Klienta z co najmniej 7 dniowym wyprzedzeniem.
            3. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług drogą elektroniczną; ustawy o prawach konsumenta, ustawy o ochronie danych osobowych.
            4. Klient ma prawo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. W tym celu może złożyć skargę za pośrednictwem unijnej platformy internetowej ODR dostępnej pod adresem: http://ec.europa.eu/consumers/odr/.
        </div>
        <br/><br/><br/><br/>
        <div className="statute">
            <h1 className="bold-text">Polityka prywatności serwisu szumstepuipustyni.pl</h1><br/>
            1. Informacje ogólne<br/>
            Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich z Serwisu.
            2. Administratorem danych osobowych zawartych w serwisie jest<br/>
            Pitagoras Tomasz Drzewiecki  z siedzibą przy ul. Kolejowej 4/4, 64-200 Wolsztyn, NIP 7842352708 , REGON 301960891<br/>
            3. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.<br/>
            4. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.<br/>
            5. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób:<br/>
            a) poprzez dobrowolnie wprowadzone w formularzach informacje<br/>
            b) poprzez gromadzenie plików “cookies” [patrz polityka plików “cookies”].<br/>
            6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.<br/>
            7. dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza np. w celu dokonania procesu obsługi kontaktu informacyjnego<br/>
            8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.<br/>
            9. Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również praw do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie.<br/>
            10. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.<br/>
            11. W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony internetowe działają niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez serwis szumstepuipustyni.pl. Strony te mogą posiadać własne polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się zapoznać.<br/>
            W razie wątpliwości co któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do dyspozycji - nasze dane znaleźć można w zakładce - KONTAKT.<br/>
        </div>
    </StyledStatuteDetails>
);

export default StatuteDetails;
